import { Controller } from "stimulus";

export default class extends Controller {
  static values = { id: Number }
  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  // connect() {
  //   if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
  //   } else {
  //     $('#search_keyword').focus();
  //   }
  // }

  // --------------------------------------------------------
  // NEW
  // --------------------------------------------------------
  new(e) {
    e.stopPropagation();
    $.ajax({
      type: "get",
      url: "/schedules/new.js",
      data: {
        schedulable_type: this.data.get("schedulable-type"),
        schedulable_id: this.data.get("schedulable-id"),
        date: e.target.getAttribute("data-date")
      },
      dataType: "script",
    });
  }
  // --------------------------------------------------------
  // EDIT
  // --------------------------------------------------------
  edit(e) {
    e.stopPropagation();
    if (e.target.tagName == 'A') { return false; }
    $.ajax({
      type: "get",
      url: "/schedules/" + e.target.closest('.schedule-item').getAttribute("data-schedule-id") + "/edit.js",
      dataType: "script",
    });
  }
}
