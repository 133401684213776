import { Controller } from "stimulus";

// jquery, dropzone はsprockets側でロード


export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    let obj = this.data.get("object");
    new Dropzone('#file_upload', {
      paramName: 'attachment[file]',
      dictDefaultMessage: 'ここにファイルをドラッグ＆ドロップしてください<br/>' +
                          '<p>または、クリックしてファイルを選択してください</p>' +
                          '<p>上限 : 10MB<br/>( ファイルは複数選択できます )</p>',
      init: function() {
        this.on('success', function(file, json) {
          let description, destroy_box, destroy_div, destroy_label, file_type, fname, icon, id, img, li;
          id = $('<input>').attr({
            'type': 'hidden',
            'value': json['id'],
            'name': obj + "[attachments_attributes][" + json['id'] + "][id]",
            'id': obj + "_attachments_attributes_" + json['id'] + "_id"
          });
          file_type = file['name'].match(/(.*)(?:\.([^.]+$))/)[2].toUpperCase();
          switch (file_type) {
            case 'XLS':
            case 'XLSX':
            case 'DOC':
            case 'DOCX':
            case 'ZIP':
            case 'PDF':
            case 'TXT':
            case 'XDW':
            case 'JWW':
              switch (file_type) {
                case 'XLS':
                case 'XLSX':
                  icon = $('<img>').attr({'src': "/assets/filetypes/xls-734e5503a145be5dedf68c47535b0dae6b37706c047999749a931b9ef04a60fc.png"}).addClass('resize');
                  break;
                case 'DOC':
                case 'DOCX':
                  icon = $('<img>').attr({'src': "/assets/filetypes/doc-ef4e47dedc13bf2bede90f435f26f7bc922d9f2c2933124a6649bfd1ea3f99c0.png"}).addClass('resize');
                  break;
                case 'TXT':
                  icon = $('<img>').attr({'src': "/assets/filetypes/txt-a4897ab8feee768598a1b26f28a7c23d31eb25340105c04c6afa8dc8d6f40926.png"}).addClass('resize');
                  break;
                case 'ZIP':
                  icon = $('<img>').attr({'src': "/assets/filetypes/zip-74acb5541c7a213b93bdda30e9d47ee136153f47ef7d96c74674a834419869b0.png"}).addClass('resize');
                  break;
                case 'XDW':
                  icon = $('<img>').attr({'src': "/assets/filetypes/dw-3fe8208748583097c5052e5ad3cf2821088eedd72c0e44c1954c6e6800d0726f.png"}).addClass('resize');
                  break;
                case 'JWW':
                  icon = $('<img>').attr({'src': "/assets/filetypes/jww-2ce37f5c3211653a001cfa5c462583910b6ff0d4909acc90765df818dea6420e.png"}).addClass('resize');
                  break;
                default:
                  icon = $('<img>').attr({'src': json['file']['thumb']['url']});
              }
              fname = $('<span>').html("" + file['name']);
              img = $('<div class=file_type>').append(icon).append(fname);
              break;
            default:
              img = $('<img>').attr({'src': json['file']['thumb']['url']});
          }
          description = $('<input>').attr({
            'style': 'margin-top: 4px;',
            'type': 'text',
            'name': obj + "[attachments_attributes][" + json['id'] + "][description]",
            'id': obj + "_attachments_attributes_" + json['id'] + "_description"
          });
          destroy_box = $('<input>').attr({
            'type': 'checkbox',
            'value': 'true',
            'name': obj + "[attachments_attributes][" + json['id'] + "][_destroy]",
            'id': obj + "_attachments_attributes_" + json['id'] + "__destroy"
          });
          destroy_label = $('<label>').attr({
            'for': obj + "_attachments_attributes_" + json['id'] + "__destroy"
          }).html("削除");
          destroy_div = $('<div style="text-align: center;">').append(destroy_box).append(destroy_label);
          li = $('<li class="cell ui-sortable-handle">').append(id).append(img).append(description).append(destroy_div);
          $('ul#uploaded-files').append(li);
        });
      }
    });
  }
}
