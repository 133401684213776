import { Controller } from "stimulus";

export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
    } else if(location.href.match(/\/\d+$/)) {
    } else {
      $('#search_keyword').focus();
    }
  }

  // --------------------------------------------------------
  // SEARCH
  // --------------------------------------------------------
  search() {
    let form = document.querySelector('form#search');
    Rails.fire(form, 'submit');
    // form.submit();
    // form.requestSubmit();
  }

  // --------------------------------------------------------
  // KEYUP -> KEYWORD SEARCH
  // --------------------------------------------------------
  // keyword_ = '';
  keyword_search(event) {
    // const thisInput = $.trim($('input#search_keyword').val());
    // // console.log("1:" + event.key);
    // if (this.keyword_ !== thisInput){
    //   this.keyword_ = thisInput;
    //   // console.log(this.keyword_ + ':' + thisInput);
    //   this.search();
    // }

    // Debounce
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.search();
    }, 300);
  }

  // --------------------------------------------------------
  // KEYUP -> KEYWORD SEARCH WITH ENTER
  // --------------------------------------------------------
  keyword_ = '';
  keyword_search_with_enter(event) {
    const thisInput = $.trim($('input#search_keyword').val());
    if (event.key == 'Enter' && this.keyword_ != thisInput){
      this.keyword_ = thisInput;
      this.search();
      $('input#search_keyword').css('color', 'black');
    } else if (this.keyword_ == thisInput) {
      $('input#search_keyword').css('color', 'black');
    } else {
      $('input#search_keyword').css('color', 'gray');
      if (thisInput.length == 0) {
        this.keyword_ = '';
        this.search();
      }
    }
  }

  // --------------------------------------------------------
  // CLEAR
  // --------------------------------------------------------
  clear() {
    $('table.status input[type=checkbox]').prop("checked", false);
    this.search();
  }
}
