import { Controller } from "stimulus";

export default class extends Controller {
  //static values = {}
  //static targets = []


  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    //console.log("Loaded");
  }

  // --------------------------------------------------------
  // CHANGE BUDGET X
  // --------------------------------------------------------
  changeBudgetX() {
    //console.log("changeCostX");
    const cost_z =
          removeYen($("#quotation_cost_2001").val()) +
          removeYen($("#quotation_cost_2002").val()) +
          removeYen($("#quotation_cost_4001").val()) +
          removeYen($("#quotation_cost_4002").val()) +
          removeYen($("#quotation_cost_4003").val()) +
          removeYen($("#quotation_cost_4004").val()) +
          removeYen($("#quotation_cost_4005").val()) +
          removeYen($("#quotation_cost_4006").val()) +
          removeYen($("#quotation_cost_4007").val());
    $("#p-cost_z").text(yen(cost_z));
    calcBudget();
  }

  // --------------------------------------------------------
  // CHANGE DETAIL
  // --------------------------------------------------------
  changeDetail(event) {
    // DETAIL LEVEL
    const detail = $(event.target).closest('.quotation_detail')
    const unit_price = detail.find('input.detail_unit_price').next('input:hidden').val();
    const quantity = detail.find('input.detail_quantity').val();
    const detail_price = Math.round(unit_price * quantity);
    const detail_total_cost = Number(detail.find('input.detail_total_cost').val());
    detail.find('input.detail_price').val(detail_price); detail.find('.p-detail_price').text(yen(detail_price));
    const detail_profit = detail_price - detail_total_cost;
    detail.find('.p-detail_profit').text(yen(detail_profit));

    // QUOTATION LEVEL
    let details_price_amount = 0;
    let legal_benefits_denominator = 0;
    $('.quotation_detail').each(function(index, elm_d){
      if($(elm_d).find('input.detail_title').val() != ''){
        $(elm_d).find('input.detail_price').each(function(index, elm){
          details_price_amount += Number($(elm).val());
          if($(elm_d).find('input.legal_benefits').prop('checked')){
            legal_benefits_denominator += Number($(elm).val());
          }
        });
      }
    });
    $('#quotation_details_price_amount').val(details_price_amount); $('#p-details_price_amount').text(yen(details_price_amount));
    $('#quotation_legal_benefits_denominator').val(legal_benefits_denominator); $('#p-legal_benefits_denominator').text(yen(legal_benefits_denominator));
    calcPrice();
  }

  // --------------------------------------------------------
  // CHANGE BUDGET A
  // --------------------------------------------------------
  changeBudgetA(event) {
    // BUDGET LEVEL
    const budget = $(event.target).closest('.quotation_budget')
    const cost_type = budget.find('select.cost_type_1').val();
    const catalog_cost = budget.find('input.budget_catalog_cost').next('input:hidden').val();
    const retail_percentage = budget.find('input.budget_retail_percentage').val();
    const unit_cost = catalog_cost * (retail_percentage / 100.0)
    budget.find('input.budget_unit_cost').val(unit_cost); budget.find('.p-budget_unit_cost').text(yen(unit_cost));
    const quantity = budget.find('input.budget_quantity').val();
    const cost = Math.round(unit_cost * quantity);
    budget.find('input.budget_cost').val(cost); budget.find('.p-budget_cost').text(yen(cost));

    // DETAIL LEVEL
    let detail_total_cost = 0;
    const detail = $(event.target).closest('.quotation_detail');
    detail.find('.quotation_budget').each(function(index, elm){
      if($(elm).find('select.cost_type_1').val() != ''){
        detail_total_cost += Number($(elm).find('input.budget_cost').val());
      }
    });
    detail.find('input.detail_total_cost').val(detail_total_cost); detail.find('.p-detail_total_cost').text(yen(detail_total_cost));
    let detail_price = Number(detail.find('input.detail_price').val());
    const detail_profit = detail_price - detail_total_cost;
    detail.find('.p-detail_profit').text(yen(detail_profit));

    // QUOTATION LEVEL

    let cost_1000 = 0;
    let cost_3000 = 0;
    $('.quotation_detail').each(function(index, elm_d){
      if($(elm_d).find('input.detail_title').val() != ''){
        $(elm_d).find('.quotation_budget').each(function(index, elm){
          const cost_type_1 = $(elm).find('select.cost_type_1').val();
          const amount = Number($(elm).find('input.budget_cost').val());
          switch(cost_type_1) {
          case '1000':
            cost_1000 += amount;
            break;
          case '3000':
            cost_3000 += amount;
            break;
          }
        });
      }
    });
    const cost_a = cost_1000 + cost_3000;
    $('#quotation_cost_1000').val(cost_1000); $('#p-cost_1000').text(yen(cost_1000));
    $('#quotation_cost_3000').val(cost_3000); $('#p-cost_3000').text(yen(cost_3000));
    $('#quotation_cost_a').val(cost_a); $('#p-cost_a').text(yen(cost_a));
    calcBudget();
  }

  // --------------------------------------------------------
  // CHANGE SUMMARY
  // --------------------------------------------------------
  changeSummary() {
    calcPrice();
  }

}

// -------------------------------------------------
// CALC PRICE
// -------------------------------------------------
function calcPrice() {
  const details_price_amount = Number($('#quotation_details_price_amount').val());
  const expense_price = Math.round(details_price_amount * ($('#quotation_expense_percentage').val() / 100));
  const legal_benefits_denominator = Number($('#quotation_legal_benefits_denominator').val());
  const legal_benefits_price = Math.round(legal_benefits_denominator * ($('#quotation_legal_benefits_percentage').val() / 100));
  const discount = $('#quotation_discount').next('input:hidden').val();
  const total_price = details_price_amount + expense_price + legal_benefits_price - discount;
  const total_tax = total_price * $('#quotation_tax_rate').val();
  $('#quotation_expense_price').val(expense_price); $('#p-expense_price').text(yen(expense_price));
  $('#quotation_legal_benefits_price').val(legal_benefits_price); $('#p-legal_benefits_price').text(yen(legal_benefits_price));
  $('#quotation_total_price').val(total_price); $('#p-total_price').text(yen(total_price));
  $('#quotation_total_tax').val(total_tax); $('#p-total_tax').text(yen(total_tax));
  $('#p-total_price_with_tax').text(yen(total_price + total_tax));
  calcBudget();
}

// -------------------------------------------------
// CALC BUDGET
// -------------------------------------------------
function calcBudget() {
  const total_price = $('#quotation_total_price').val();
  const cost_a = removeYen($('#p-cost_a').text());
  const cost_z = removeYen($('#p-cost_z').text());
  const total_cost = cost_a + cost_z;
  const profit_a = total_price - cost_a;
  const profit_b = total_price - total_cost;
  $('#quotation_total_cost').val(total_cost); $('#p-total_cost').text(yen(total_cost));
  $('#p-profit_a').text(yen(profit_a));
  $('#p-profit_b').text(yen(profit_b));
  $('#p-profit_rate_a').text(percentage(profit_a / total_price));
  $('#p-profit_rate_b').text(percentage(profit_b / total_price));
}

// -------------------------------------------------
// 数字→金額
// -------------------------------------------------
function yen(n) {
  return '¥ ' + Number(n).toLocaleString();
}

// -------------------------------------------------
// 金額→数字
// -------------------------------------------------
function removeYen(s) {
  return Number(s.replace('¥ ', '').replace(/,/g, ''));
}

// -------------------------------------------------
// PERCENTAGE
// -------------------------------------------------
function percentage(n) {
  return (Math.round(n * 1000) / 10).toString() + '%'
}
