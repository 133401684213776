import { Controller } from "stimulus";

// jquery, jquery.autoKana はsprockets側でロード

export default class extends Controller {
  static targets = [
    "kanji",
    "kana"
  ]

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    $.fn.autoKana(
      $(this.kanjiTarget), $(this.kanaTarget),
      {
        katakana : false  //true：カタカナ、false：ひらがな（デフォルト）
      });
  }
}
