import { Controller } from "stimulus";

export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    const elm_sort = document.querySelector('input#search_sort');
    const th_sort = document.querySelector('th#' + elm_sort.value);
    const direction = document.querySelector('input#search_direction');
    // console.log(th_sort);
    th_sort.classList.add(direction.value);

    const nodeSort = document.querySelectorAll("th.sort");
    nodeSort.forEach((elmSort) => {
      elmSort.setAttribute('data-action', 'click->table#sort');
    });
  }

  // --------------------------------------------------------
  // SORT
  // --------------------------------------------------------
  sort(event) {
    const elm_sort = document.querySelector('input#search_sort');
    const elm_direction = document.querySelector('input#search_direction');
    const sort_key = event.target.getAttribute("id");
    if(elm_sort.value == sort_key){
      if(elm_direction.value == 'asc'){
        elm_direction.value = 'desc';
      }else{
        elm_direction.value = 'asc';
      }
    }else{
      elm_sort.value = sort_key;
      elm_direction.value = 'asc';
    }
    const form = document.querySelector('form#search');
    Rails.fire(form, 'submit');
  }
}
