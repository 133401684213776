import { Controller } from "stimulus";

export default class extends Controller {
  static values = { orderCost: Number }
  static targets = [
    "approvedOn",
    "paidOn",
    "status",
  ]
  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  // connect() {
  //   if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
  //   } else {
  //     $('#search_keyword').focus();
  //   }
  // }

  // --------------------------------------------------------
  // APPROVE
  // --------------------------------------------------------
  approve() {
    let approvedOn = this.approvedOnTarget;
    let status = this.statusTarget;
    $.ajax({
      type: "post",
      url: "/order_costs/approve",
      data: {
        order_cost_id: this.orderCostValue
      },
      dataType: "html",
    }).done(function(data){
      approvedOn.textContent = data;
      status.textContent = "支払待";
    });
  }
  // --------------------------------------------------------
  // PAY
  // --------------------------------------------------------
  pay() {
    let paidOn = this.paidOnTarget;
    let status = this.statusTarget;
    $.ajax({
      type: "post",
      url: "/order_costs/pay",
      data: {
        order_cost_id: this.orderCostValue
      },
      dataType: "html",
    }).done(function(data){
      paidOn.textContent = data;
      status.textContent = "支払済";
    });
  }
}
