import { Controller } from "stimulus";
import moment from "moment";

// jquery, datepicker はsprockets側でロード

export default class extends Controller {
  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    const d = new Date();
    const mindate = this.data.get("mindate") ? new Date(this.data.get("mindate")) : moment(d).add(-10, 'years').toDate();
    const maxdate = this.data.get("maxdate") ? new Date(this.data.get("maxdate")) : moment(d).add(5, 'years').toDate();
    const changeyear = true;
    $(this.element).datepicker({
      minDate: mindate,
      maxDate: maxdate,
      changeYear: changeyear,
      changeMonth: true,
      yearRange: '-100:+50',
      showOtherMonths: true,
      selectOtherMonths: true,
      onSelect: function() {
        triggerEvent(this, 'change');
      }
    });
    this.element.readOnly = true;
  }
}

$.datepicker.setDefaults({
  dateFormat: "yy-mm-dd",
  showButtonPanel: true,
  beforeShow: showAdditionalButton,
  onChangeMonthYear: showAdditionalButton
});

$.datepicker._gotoToday = function(id) {
	var target = $(id);
	var inst = this._getInst(target[0]);
	var date = new Date();
	this._setDate(inst,date);
	this._hideDatepicker();
}

function showAdditionalButton(input) {
  setTimeout((function() {
    let buttonPanel = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');
    let btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" type="button">クリア</button>');
    btn.unbind('click').bind('click', function() {
      $.datepicker._clearDate(input);
    });
    btn.appendTo(buttonPanel);
  }), 1);
};

function triggerEvent(element, event) {
  if (document.createEvent) {
    // IE以外
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true ); // event type, bubbling, cancelable
    return element.dispatchEvent(evt);
  } else {
    // IE
    var evt = document.createEventObject();
    return element.fireEvent("on"+event, evt)
  }
}

// --------------------------------------------------------
// BACK BUTTON HACK
// --------------------------------------------------------
document.addEventListener("turbolinks:before-cache", function() {
  $.datepicker.dpDiv.remove();
  $("input.hasDatepicker").each(function(){
    const elm = $(this);
    elm.datepicker("destroy");
  })
});

document.addEventListener("turbolinks:before-render", function(event) {
  $.datepicker.dpDiv.appendTo(event.data.newBody);
});
