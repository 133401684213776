import { Controller } from "stimulus";

export default class extends Controller {
  static values = { site: Number }
  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  // connect() {
  //   if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
  //   } else {
  //     $('#search_keyword').focus();
  //   }
  // }

  // --------------------------------------------------------
  // OPEN
  // --------------------------------------------------------
  open() {
    $.ajax({
      type: "get",
      url: "/sites/customer_list.js",
      data: {
        site_id: this.siteValue
      },
      dataType: "script",
    });
  }

  // --------------------------------------------------------
  // CLEAR
  // --------------------------------------------------------
  clear() {
    $('table.status input[type=checkbox]').prop("checked", false);
    this.search();
  }
}
